import { call, put, takeLatest } from "redux-saga/effects";
import {
  setItineraries,
  setAirlines,
  setIsLoading,
  setError,
  fetchItinerary,
  fetchAirlines,
} from "../itinerarySlice";
import { fetchItineraryData } from "../../utils/api";

import {
  getLocalStorageData,
  buildOriginDestinationInfo,
  buildPassengerTypes,
  processItinerary,
} from "@/utils/searchResultFunction";
 function* fetchItinerarySaga() {
  yield put(setIsLoading(true)); // Set loading state to true before the API call
  console.log("Saga started");
  try {
    const {
      departureDates,
      departureAirports,
      arrivalAirports,
      adults,
      kids,
      children,
      infants,
    } = getLocalStorageData();

    const originDestinationInfo = buildOriginDestinationInfo(
      departureDates,
      departureAirports,
      arrivalAirports
    );
    const passengerTypes = buildPassengerTypes(adults, kids, children, infants);

    const data = yield call(
      fetchItineraryData,
      originDestinationInfo,
      passengerTypes
    );
    const processedData = processItinerary(data);

    yield put(setItineraries(processedData)); // Store the itineraries in the state
  } catch (error) {
    yield put(setError(error.message)); // Handle errors by storing them in the state
    console.error("Error fetching itinerary data:", error);
  } finally {
    yield put(setIsLoading(false)); // Set loading state to false after the API call
    console.log("Loading state set to false");
  }
}

function* fetchAirlinesSaga() {
  yield put(setError(null));
  try {
    const response = yield call(
      fetch,
      "https://api.hamsfly.com/api/carrierLogo"
    );
    if (!response.ok) {
      throw new Error("Failed to fetch airline data");
    }
    const data = yield response.json();
    yield put(
      setAirlines(
        data.carrierLogo.map(({ code, logo, name }) => ({ code, logo, name }))
      )
    );
  } catch (error) {
    yield put(setError(error.message));
    console.error("Error fetching airlines:", error);
  }
}

export function* itinerarySaga() {
  yield takeLatest(fetchItinerary.type, fetchItinerarySaga);
  yield takeLatest(fetchAirlines.type, fetchAirlinesSaga);
}
