 
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    passengerInfo : {}
};

const passengerInfoSlice = createSlice({
  name: 'passengerInfo',
  initialState,
  reducers: {
    setPassengerInfo(state, action) {
      state.passengerInfo = action.payload;
    },
  },
});

export const { setPassengerInfo } = passengerInfoSlice.actions;

export default passengerInfoSlice.reducer;
