import React from "react";
import "./footer.css";

const Newsletter = () => {
  return (
    <div className="container element max-w-[1200px] mx-auto px-4 lg:px-4 rounded-[20px] py-10">
      <h3 className="text-center text-white text-xl md:text-3xl font-semibold mb-6">
        Subscribe to our newsletter
      </h3>
      <form className="flex gap-3 justify-center">
        <input
          type="email"
          required
          placeholder="Enter your email"
          className="border bg-transparent text-sm md:text-base text-white placeholder:text-white placeholder:font-extralight px-2 md:px-3 rounded-md focus:outline-none"
        />
        <button className="bg-white text-sm md:text-base hover:bg-[#e2e2e2] active:scale-95 px-5 py-3 rounded-md text-black font-semibold">
          Subscribe Now
        </button>
      </form>
    </div>
  );
};

export default Newsletter;
