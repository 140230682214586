import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import authReducer from "./authSlice";
import filterItinerariesReducer from "./filterItinerariesSlice";
import itineraryPriceReducer from "./itineraryPriceSlice";
import itineraryReducer from "./itinerarySlice";
import rootSaga from "./sagas/rootSaga";
import flightsReducer from "./flightsSlice";
import passengerInfoReducer from "./passengerInfoSlice";
import flightBookReducer from "./flightBookingSlice";
import revalidateReducer from "./revalidateDataSlice";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: {
    auth: authReducer,
    filterItinerary: filterItinerariesReducer,
    itineraryPrice: itineraryPriceReducer,
    itinerary: itineraryReducer,
    passengerInfo: passengerInfoReducer,
    flight: flightsReducer,
    flightBook: flightBookReducer,
    revalidateData: revalidateReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export default store;